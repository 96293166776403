import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import fullscreenIcon from '../../assets/images/fullscreen.svg';

const RemoteMedia = (props) => {
  const [volume, setVolume] = useState(0.3);
  const [screenView, setScreenView] = useState('contain');
  const { microPhone, voiceCall, isCustomerFavourite } = props;
  const [isFav, setIsFav] = useState(false);

  // useEffect(() => {
  //   const remoteVideo = document.getElementById('remote-media-div');
  //   if (remoteVideo && remoteVideo.children && remoteVideo.children.length > 0) {
  //     remoteVideo.children[0].volume = volume;
  //     // remoteVideo.children[1].volume = volume;
  //   }
  // }, [volume]);

  useEffect(() => {
    setIsFav(isCustomerFavourite);
  }, [isCustomerFavourite]);

  const handleFavUnFavIconClick = (val) => {
    setIsFav(val);
    // eslint-disable-next-line react/destructuring-assignment
    props.favUnFavHandlerFun(val);
  };

  const microPhoneHandler = (action) => {
    microPhone(action);
    if (action === 'mute') {
      setVolume(0);
    } else {
      setVolume(0.5);
    }
  };

  const changeScreenView = () => {
    const newScreenView = screenView === 'contain' ? 'fill' : 'contain';
    setScreenView(newScreenView);
    // document.getElementById('remote-media-div').childNodes[0].style.objectFit = newScreenView;
    document.getElementById('remote-media-div').childNodes[1].style.objectFit = newScreenView;
  };

  const renderVolumeIcon = () => {
    if (volume > 0) {
      return (
        <div className="icons-container">
          <i
            role="presentation"
            onClick={() => microPhoneHandler('mute')}
            className={voiceCall ? 'fa fa-microphone volumeIcon voice-call' : 'fa fa-microphone volumeIcon'}
          />
          {voiceCall ? ''
            : (
              <div className="fullScreenIconWrap">
                <img role="presentation" className="fullScreenIcon" src={fullscreenIcon} alt="viewFullScreen" onClick={changeScreenView} />
              </div>
            )
          }
          {isFav ? (
            <div className="fav-icon-wrap-call enable-fav" onClick={() => handleFavUnFavIconClick(false)} role="presentation">
              <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
              </svg>
            </div>
          ) : (
            <div className="fav-icon-wrap-call disable-fav" onClick={() => handleFavUnFavIconClick(true)} role="presentation">
              <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
              </svg>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="icons-container">
        <i
          role="presentation"
          onClick={() => microPhoneHandler('unmute')}
          className={voiceCall ? 'fa fa-microphone-slash volumeIcon voice-call' : 'fa fa-microphone-slash volumeIcon'}
        />
        {voiceCall ? ''
          : (
            <div className="fullScreenIconWrap">
              <img role="presentation" className="fullScreenIcon" src={fullscreenIcon} alt="viewFullScreen" onClick={changeScreenView} />
            </div>
          )}
        {isFav ? (
          <div className="fav-icon-wrap-call enable-fav" onClick={() => handleFavUnFavIconClick(false)} role="presentation">
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
            </svg>
          </div>
        ) : (
          <div className="fav-icon-wrap-call disable-fav" onClick={() => handleFavUnFavIconClick(true)} role="presentation">
            <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.3777 19.0723C-8.62195 8.01822 5.37804 -3.98178 11.3777 3.60628C17.3781 -3.98178 31.3781 8.01822 11.3777 19.0723Z" fill="#FD7663" fillOpacity="0.3" stroke="#F3F3F3" strokeWidth="1.8" />
            </svg>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        id="remote-media-div"
        className="remote-media-div"
      />

      {renderVolumeIcon()}

      {/* <InputRange
        maxValue={1}
        minValue={0}
        step={0.01}
        value={volume}
        onChange={setVolume}
      /> */}
    </>
  );
};

RemoteMedia.defaultProps = {
  voiceCall: false,
  isCustomerFavourite: false,
};

RemoteMedia.propTypes = {
  microPhone: PropTypes.func.isRequired,
  voiceCall: PropTypes.bool,
  favUnFavHandlerFun: PropTypes.func.isRequired,
  isCustomerFavourite: PropTypes.bool,
};

export default RemoteMedia;
